<template>
    <div class="page">
        <Part1 />
        <Part2 style="border:solid 1px #ddd;overflow:hidden"/>
        <!-- <Part3 /> -->
        <!-- <ApplyForm /> -->
    </div>
</template>

<script>
import Part1 from './part1'
import Part2 from './part2'
// import Part3 from './part3'
// import ApplyForm from '../../components/ApplyForm'

export default {
    components: { Part1,Part2 },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
    
</style>
