import { render, staticRenderFns } from "./part1.vue?vue&type=template&id=1a106e8d&scoped=true&"
import script from "./part1.vue?vue&type=script&lang=js&"
export * from "./part1.vue?vue&type=script&lang=js&"
import style0 from "./part1.vue?vue&type=style&index=0&id=1a106e8d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a106e8d",
  null
  
)

export default component.exports