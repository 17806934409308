<template>
    <div class="container">
        <!-- <img src="../../assets/banner/home-title.png" alt=""> -->
        <p class="title">风险管理的领航者</p>
        <p class="p">
            基于华尔街最新一代多因子和动态-厚尾风险模型，结合智君科技的建模与风控经验<br>
            提供股票、债券、基金组合管理的风险识别、风险控制、绩效归因、组合优化<br>
            等多场景解方案
        </p>
        <button @click="handleButtonClick">方案咨询</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        handleButtonClick() {
            window.scroll({
                top: document.body.clientHeight,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    overflow-x: hidden;
    width:100%;
    height: 320px;
    // margin-top: -60px;
    background: url('../../assets/banner/home.png') no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 17%;
    img{
        width: 760px;
        height: 47px;
    }
    .p, button{
        font-size: 16px;
        color: #ffffff;
    }
    .p{
        line-height: 22px;
        text-align: left;
        margin: 10px 0;
    }
    button{
        width: 128px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid #ffffff;
        background: transparent;
        cursor: pointer;
        outline: none;
        &:active{
            border: 1px solid #ddd;
            color: #ddd;
        }
    }
    .title{
        font-family: 'zcool_gaoduanhei', sans-serif;
        width: 480px;
        height: 60px;
        font-size: 60px;
        color: #ffffff;
    }
}
</style>