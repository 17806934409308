<template>
    <div class="main">
        <div class="top">
            <div class="tit">市场表现及风险</div>
            <div class="bread">
                <div class="crumb">
                    <span>{{title}}</span><span>{{sep}}</span><span>{{subTitle}}</span>
                </div>
                
                <span class="date">
                    <span>{{nowDate}}</span> &nbsp; &nbsp;
                    <span>{{nowWeek}}</span> 
                </span>
            </div>
        </div>
        
        <div class="con">
            <el-menu class="nav"
                :default-active="activeIndex"
                :default-openeds="[menuList[0].index]">
                <section v-for="(item, index) in menuList" :key="index">
                    <el-submenu v-if="item.children.length" :index="item.index">
                        <template slot="title">
                            <div class="sub-menu">
                                <svg-icon :icon-class="item.icon"></svg-icon>
                                {{item.name}}
                            </div>
                        </template>
                        <el-menu-item v-for="(sub, i) in item.children" :key="i" :index="sub.index">
                            <a :href="sub.path" target="_self">{{sub.name}}</a>
                        </el-menu-item >
                    </el-submenu>
                    <el-menu-item v-else :index="item.index" class="sub-menu">
                        <template slot="title">
                            <a :href="item.path" target="_self">
                                <svg-icon :icon-class="item.icon"></svg-icon>
                                {{item.name}}
                            </a>
                        </template>
                    </el-menu-item>
                </section>
            </el-menu>

            <div class="chart" style="width:87.39%;border-left:solid 1px #ddd;">
                <div style="width:87.39%;">
                    <transition name="transitionRouter" style="width:87.39%;">
                        <router-view></router-view>
                    </transition>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            timer: null,
            nowWeek: '',
            nowDate: '',

            title:'',
            subTitle:'',
            sep:'',

            menuList: [{
                name: '股指风险',
                path: '/',
                index: '1',
                icon: 'gu',
                children: [
                    {name: '沪深', path: '/#/market/hushen', index: '1-1'},
                    {name: '美国', path: '/#/market/america', index: '1-2'},
                    {name: '亚太', path: '/#/market/asiapacific', index: '1-3'},
                    {name: '欧洲', path: '/#/market/europe', index: '1-4'}
                ]
            },{
                name: '风格因子',
                path: '/#/market/fengge',
                index: '2',
                icon: 'feng',
                children: []
            }],
            activeIndex: ''
        }
    },
    mounted(){
        const {activeRootMenu, activeMenu} = this.getActiveMenu(this.$route.path)
        this.activeIndex = activeMenu.index
        this.title = activeRootMenu.name
        this.sep = activeMenu.icon ? '' : '>'
        this.subTitle = activeMenu.icon ? '' : activeMenu.name
        this.setNowTimes()
    },
    methods:{
        getActiveMenu(path) {
            let activeRootMenu, activeMenu
            this.menuList.forEach(menu => {
                if (menu.path == '/#' + path) {
                    activeMenu = menu
                    activeRootMenu = menu
                }
                menu.children.length && menu.children.forEach(sub => {
                    if (sub.path == '/#' + path) {
                        activeMenu = sub
                        activeRootMenu = menu
                    }
                })
            })
            return {activeRootMenu, activeMenu}
        },
        setNowTimes () {
            let myDate = new Date()
            let wk = myDate.getDay()
            let yy = String(myDate.getFullYear())
            let mm = myDate.getMonth() + 1
            let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
            let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
            let week = weeks[wk]
            this.nowDate = yy + '年' + mm + '月' + dd +'日'
            this.nowWeek = week
        }
    }
}
</script>

<style lang="less" scoped>
ul li{
    list-style: none;
    margin:0;
    padding:0;
}
.main{
    width:1340px;
    min-height: 900px;
    margin:0 auto;
    .top{
        width:1340px;
        height:60px;
        margin:0;
        .tit{
            width:12.5%;
            height:60px;
            background:#fff;
            text-align: center;
            line-height: 60px;
            font-size: 18px;
            font-weight:bold;
            border-right:solid 1px #ddd;
            font-family:PingFangSC-Medium,PingFang SC;
            float:left;
            color:#333;
        }
        .bread{
            width:86%;
            height:60px;
            line-height:60px;
            float:left;
            background:#fff;
            text-align:left;
            .crumb{
                float:left;
                margin-left:19px;
                font-size:16px;
                font-family:PingFangSC-Medium,PingFang SC;
                font-weight:bold;
                color:rgba(51,51,51,1);
            }
            .date{
                float:right;
                margin-right:-4px;
                color:#666;
                font-size:14px;
                font-weight:400;
                font-family:PingFangSC-Regular,PingFang SC;
            }
        }
        
    }
    .con{
        width:100%;
        min-height:800px;
        background:#fff;
        padding:0;
        margin:0;
        .nav{
            width:12.5%;
            background-color: #fff;
            overflow:hidden;
            float:left;
            color:#333;
            font-size: 14px;
            border-top:solid 1px #ddd;
            border-right:none;
            &.el-menu /deep/ .el-submenu .el-menu-item{
                padding-left: 0 !important;
                margin-left: -26px;
            }
            &.el-menu /deep/ .el-menu-item{
                cursor: pointer;
                a{
                    text-decoration: none;
                    color: rgba(0,0,0,.65);
                    display: block;
                    user-select: none;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                }
                &.is-active{
                    background:#5F80A9;
                    a{
                        color:#fff;
                    }
                }
            }
        }
        .chart{
            width:87.5%;
            min-height:800px;
            float:right;
        }

        /deep/ .sub-menu{
            padding-left: 0px;
            &.el-menu-item{
                padding-left: 20px !important;
            }
            height:50px;
            line-height:52px;
            font-size:14px;
            font-weight:bold;
            text-align: left;
            .svg-icon{
                margin-right: 4px;
            }
        }
    }
}
.left {
  height: 100%;
}

// .transitionRouter-enter-active,
// .transitionRouter-leave-active {
//     transition: all 0s;
// }

// .transitionRouter-enter,
// .transitionRouter-leave{
//     transform: translate3d(100%, 0, 0) ;
// }
</style>